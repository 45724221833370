<template>
  <div>
    <page-title>首页看板</page-title>
    <div class="desktop-block">
      <PermissionEditor :permission-tree="permissionTree" v-model:checked="checked"></PermissionEditor>
      <a-button type="default" @click="load">加载</a-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import PageTitle from '@/components/PageTitle.vue'
import PermissionEditor from '@/components/PermissionEditor.vue'
import { PermissionGroup } from '@/models/Employee'
import { getPermissionTree } from '@/api/employee_api'
import { Ref, ref } from 'vue'

const permissionTree: Ref<PermissionGroup[]> = ref([])
const checked: Ref<number[]> = ref([10002, 10003])

async function load() {
  const response = await getPermissionTree()
  if (response.errorCode === 0 && response.data != null) {
    permissionTree.value = response.data.rows
  }
}

</script>
