<template>
  <div class="title">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
.title {
  height: 22px;
  line-height: 22px;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
  margin: 24px;
}
</style>
