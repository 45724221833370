<template>
  <div>
    <page-title>门店配置</page-title>
    <div class="desktop-block">
      <div class="container">
        <a-form name="basic" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" autocomplete="off">
          <a-form-item label="门店名称" name="name">
            <a-input placeholder="请输入门店名称" />
          </a-form-item>

          <a-form-item label="门店地址" name="name">
            <a-input placeholder="请输入门店地址" />
          </a-form-item>

          <a-form-item label="门店照片" name="name">
            <a-upload v-model:file-list="fileList" action="#" accept=".png,.jpg" :maxCount="1" name="merchantPicture"
              :withCredentials="true" list-type="picture-card" @preview="handlePreview">
              <div v-if="uploadVisible">
                <plus-outlined style="font-size: 24px; color: #adadad;" />
              </div>
            </a-upload>
          </a-form-item>
          <a-button type="primary">保存</a-button>
          <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue'
import { computed, ref } from 'vue'
import type { UploadProps } from 'ant-design-vue'
import PageTitle from '@/components/PageTitle.vue'

function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const previewVisible = ref(false)
const previewImage = ref('')
const previewTitle = ref('')

const fileList = ref<UploadProps['fileList']>([
])

const uploadVisible = computed(() => (fileList.value == null || fileList.value.length === 0))

const handleCancel = () => {
  previewVisible.value = false
  previewTitle.value = ''
}

const handlePreview = async (f: any) => {
  const file = { ...f }
  if (!file.url && !file.preview) {
    file.preview = (await getBase64(file.originFileObj)) as string
  }
  previewImage.value = file.url || file.preview
  previewVisible.value = true
  previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
}

</script>

<style scoped>
.container {
  width: 400px;
  margin: 0 auto;
}
</style>
