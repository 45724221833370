<template>
  <div id="app">
    <a-config-provider :locale="locale" :autoInsertSpaceInButton="false">
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import zhCN from 'ant-design-vue/es/locale/zh_CN'

// 国际化配置
dayjs.locale('zh-cn')
const locale = zhCN

</script>
<style>
</style>
