import { ApiResponse } from '@/api/api_base'
import {
  Employee, EmployeeList, PositionList, PermissionList
} from '@/models/Employee'
import sleep from '@/utils/utils'

// 获取单个员工的详情
export async function getEmployee(employeeCode: number): Promise<ApiResponse<Employee>> {
  const data: Employee = {
    employeeCode,
    name: '廖增祥',
    gender: 'male',
    phone: '13012345678',
    positions: [101, 102, 106],
    positionDescs: ['店长', '钢琴教师', '前台'],
    createDate: '2024-02-06',
    hasBondWeChat: true
  }

  return {
    errorCode: 0,
    msg: 'OK',
    data
  }
}

// 查询员工列表
export async function getEmployeeList(): Promise<ApiResponse<EmployeeList>> {
  // const response = await callApi<EmployeeList>('/abc', 'GET')
  // return response

  // 返回模拟数据
  const data: EmployeeList = {
    total: 5,
    page: 1,
    pageSize: 10,
    rows: [
      {
        employeeCode: 10001,
        name: '张三',
        gender: 'male',
        phone: '13012345678',
        positions: [104, 105, 106],
        positionDescs: ['爵士鼓教师', '销售', '前台'],
        createDate: '2024-02-06',
        hasBondWeChat: true
      },
      {
        employeeCode: 10002,
        name: '李四',
        gender: 'male',
        phone: '13012345678',
        positions: [102, 103],
        positionDescs: ['钢琴教师', '吉他教师'],
        createDate: '2024-02-06',
        hasBondWeChat: true
      },
      {
        employeeCode: 10003,
        name: '廖增祥',
        gender: 'male',
        phone: '13012345678',
        positions: [101, 102, 106],
        positionDescs: ['店长', '钢琴教师', '前台'],
        createDate: '2024-02-06',
        hasBondWeChat: true
      },
      {
        employeeCode: 10004,
        name: '李四方',
        gender: 'female',
        phone: '13012345678',
        positions: [103],
        positionDescs: ['吉他教师'],
        createDate: '2024-02-06',
        hasBondWeChat: false
      },
      {
        employeeCode: 10005,
        name: '杨贵妃',
        gender: 'female',
        phone: '13012345678',
        positions: [107],
        positionDescs: ['迎宾'],
        createDate: '2024-02-06',
        hasBondWeChat: false
      }
    ]
  }

  // 模拟网络延时
  await sleep(200)

  return {
    errorCode: 0,
    msg: 'OK',
    data
  }
}

export async function getPositionList(): Promise<ApiResponse<PositionList>> {
  // 返回模拟数据
  const data:PositionList = {
    total: 1,
    page: 1,
    pageSize: 10,
    rows: [
      {
        positionCode: 101,
        name: '店长',
        createTime: '2024-02-06 01:52:30'
      },
      {
        positionCode: 102,
        name: '钢琴教师',
        createTime: '2024-02-06 01:52:30'
      },
      {
        positionCode: 103,
        name: '吉他教师',
        createTime: '2024-02-06 01:52:30'
      },
      {
        positionCode: 104,
        name: '爵士鼓教师',
        createTime: '2024-02-06 01:52:30'
      },
      {
        positionCode: 105,
        name: '销售',
        createTime: '2024-02-06 01:52:30'
      },
      {
        positionCode: 106,
        name: '前台',
        createTime: '2024-02-06 01:52:30'
      },
      {
        positionCode: 107,
        name: '迎宾',
        createTime: '2024-02-06 01:52:30'
      }
    ]
  }

  // 模拟网络延时
  await sleep(200)

  return {
    errorCode: 0,
    msg: 'OK',
    data
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function erasePosition(positionCode: number): Promise<ApiResponse<null>> {
  // 模拟网络延时
  await sleep(500)

  return {
    errorCode: 0,
    msg: 'OK'
  }
}

export async function getPermissionTree(): Promise<ApiResponse<PermissionList>> {
  const data: PermissionList = {
    total: 1,
    page: 1,
    pageSize: 10,
    rows: [
      {
        groupName: '员工管理',
        permissions: [
          {
            permissionCode: 10001,
            name: '职位列表'
          },
          {
            permissionCode: 10002,
            name: '职位管理'
          },
          {
            permissionCode: 10003,
            name: '员工列表'
          },
          {
            permissionCode: 10004,
            name: '员工管理'
          }
        ]
      },
      {
        groupName: '店铺管理',
        permissions: [
          {
            permissionCode: 20001,
            name: '店铺管理'
          }
        ]
      }
    ]
  }

  // 模拟网络延时
  await sleep(300)

  return {
    errorCode: 0,
    msg: 'OK',
    data
  }
}
