<template>
  <div>
    <a-tree :v-if="data.length" :checkable="true" :defaultExpandAll="true" :tree-data="data" :expandedKeys="expandedKeys"
      v-model:checkedKeys="checkedKeys" @check="onCheckClicked">
    </a-tree>
  </div>
</template>

<script setup lang="ts">
import { PermissionGroup } from '@/models/Employee'
import {
  Ref, ref, defineProps, watch, nextTick, defineEmits, onMounted
} from 'vue'
import { TreeDataItem } from '@/models/Types'

const props = defineProps<{
  permissionTree: PermissionGroup[]
  checked: number[]
}>()

// 定义组件的事件
const emits = defineEmits(['update:checked'])

// 定义树型组件的数据
const data: Ref<TreeDataItem[]> = ref([])

// 定义树形组件展开的节点
const expandedKeys: Ref<number[]> = ref([])

// 定义被选中的双向绑定数据对象
const checkedKeys: Ref<number[]> = ref([])

function initPermissionTree(newValue: PermissionGroup[]) {
  data.value = []
  expandedKeys.value = []
  newValue.forEach((permissionGroup, index) => {
    const children: TreeDataItem[] = []
    permissionGroup.permissions.forEach((permission) => {
      const subNode: TreeDataItem = {
        title: permission.name,
        key: permission.permissionCode
      }
      children.push(subNode)
    })

    const treeNode: TreeDataItem = {
      title: permissionGroup.groupName,
      key: -index,
      children
    }

    data.value.push(treeNode)
    expandedKeys.value.push(treeNode.key)
  })

  nextTick(() => {
    checkedKeys.value = [...props.checked]
  })
}

onMounted(() => {
  initPermissionTree(props.permissionTree)
})

watch(() => props.permissionTree, (newValue: PermissionGroup[]) => {
  initPermissionTree(newValue)
}, { deep: true })

function onCheckClicked() {
  emits('update:checked', checkedKeys.value)
}

</script>
