<template>
  <a-flex class="container" justify="center" align="center">
    <a-flex class="bg2" justify="center" align="center">
      <a-flex class="login_window" justify="space-between" align="center">
        <div class="left">
          <a-carousel :autoplay="true" :autoplaySpeed="8000">
            <div class="intro_window">
              <img src="@/assets/images/intro1.png" alt="" />
              <div class="keyword">精准营销</div>
              <div class="desc">根据历史消费了解会员喜好，实施精准营销</div>
            </div>
            <div class="intro_window">
              <img src="@/assets/images/intro2.svg" alt="" />
              <div class="keyword">数据分析</div>
              <div class="desc">用数据分析为营销提供有力的支撑</div>
            </div>
            <div class="intro_window">
              <img src="@/assets/images/intro3.svg" alt="" />
              <div class="keyword">数据安全</div>
              <div class="desc">提供数据加密及自动备份，保障数据安全</div>
            </div>
          </a-carousel>
        </div>
        <div class="right">
          <div class="login_title">登录</div>
          <a-input :allowClear="true" size="large" placeholder="用户名/手机号码">
            <template #prefix>
              <UserOutlined style="color:#c9c9c9; margin-right:5px;" />
            </template>
          </a-input>
          <div class="gap"></div>
          <a-input-password :allowClear="true" size="large" placeholder="密码">
            <template #prefix>
              <UnlockOutlined style="color:#c9c9c9; margin-right:5px;" />
            </template>
          </a-input-password>
          <div class="gap"></div>
          <a-input :allowClear="true" size="large" placeholder="验证码">
            <template #prefix>
              <SafetyOutlined style="color:#c9c9c9; margin-right:5px;" />
            </template>
            <template #suffix>
              <a-tooltip color="blue" placement="right">
                <template #title>点击更新验证码</template>
                <img src="@/assets/images/code.svg" class="captcha" alt="" @keydown.prevent="onRefreshCaptcha"
                  @click.prevent="onRefreshCaptcha" />
              </a-tooltip>
            </template>
          </a-input>
          <div class="gap"></div>
          <a-button type="primary" size="large" :loading="loading" style="width: 100%;" @click="onLogin">登录</a-button>
        </div>
      </a-flex>
    </a-flex>
  </a-flex>
  <div class="footer">
    Copyright © 2024 moodwu.com. All rights reserved. 粤ICP备14016483号-3
  </div>
</template>

<script setup lang="ts">
import sleep from '@/utils/utils'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { UserOutlined, UnlockOutlined, SafetyOutlined } from '@ant-design/icons-vue'

const loading = ref(false)
const router = useRouter()

async function onLogin() {
  loading.value = true
  await sleep(1000)
  loading.value = false
  router.push('/')
}

function onRefreshCaptcha() {
  // TODO: 更新验证码
}

</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(@/assets/images/login_bg.svg);
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-clip: border-box;
  background-color: #f3f3f3;
}

.bg2 {
  width: 90%;
  height: 78%;
  background-image: url(@/assets/images/login_bg2.svg);
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.login_window {
  width: 900px;
  max-width: 900px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 4px 4px 5px #00000072;
  min-height: 100px;
}

.left {
  width: 450px;
  background-color: #e9e9e9;
}

.right {
  width: 450px;
  padding: 0 70px;
}

:deep(.slick-slide) {
  text-align: center;
  height: 460px;
  line-height: 460px;
  background: #e9e9e9;
  overflow: hidden;
}

:deep(.slick-slide h3) {
  color: #000;
}

:deep(.slick-dots li.slick-active button) {
  background-color: #2f79e8;
}

:deep(.slick-dots li button) {
  background-color: #2f79e8;
}

.intro_window {
  height: 460px;
  width: 450px;
}

.intro_window img {
  margin: 20px auto;
  width: 300px;
  height: 300px;
}

.keyword {
  margin: 0 auto;
  width: 300px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
}

.desc {
  margin: 0 auto;
  width: 300px;
  height: 24px;
  line-height: 24px;
  font-size: 15px;
  color: #000;
}

.gap {
  height: 18px;
}

.login_title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 16px;
  padding-bottom: 40px;
}

.captcha {
  height: 24px;
  cursor: pointer;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
}
</style>
