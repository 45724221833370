<template>
  <div>
    <page-title>员工列表</page-title>
    <div class="desktop-block">
      <a-flex justify="space-between" align="center">
        <a-space size="large">
          <div>姓名：<a-input class="filter_input150" placeholder="员工姓名" :allowClear="true" /></div>
          <div>电话：<a-input class="filter_input150" placeholder="手机号码" :allowClear="true" /></div>
          <div>入职日期：<a-range-picker :allowClear="true" /></div>
          <a-button type="primary" shape="circle" :icon="h(SearchOutlined)" @click="query"></a-button>
        </a-space>
        <a-button type="default" danger :icon="h(PlusOutlined)" @click="onAddEmployee">新增员工</a-button>
      </a-flex>
      <div class="v-space-24"></div>
      <a-table :columns="columns" :data-source="data" rowKey="employeeCode" :loading="loading">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'name'">
            <a>
              {{ record.name }}
            </a>
          </template>
          <template v-if="column.key === 'gender'">
            {{ record.gender == 'male' ? '男' : '女' }}
          </template>
          <template v-else-if="column.key === 'position'">
            <span>
              <a-tag v-for="position in record.positionDescs" :key="position" color="blue">
                {{ position }}
              </a-tag>
            </span>
          </template>
          <template v-else-if="column.key === 'hasBondWeChat'">
            <span>
              {{ record.hasBondWeChat ? '已绑定' : '未绑定' }}
            </span>
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <a>详情</a>
              <a-divider type="vertical" />
              <a-dropdown>
                <a class="ant-dropdown-link">更多</a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="0">
                      <a href="#" rel="noopener noreferrer" @click.prevent="onEditEmployee(record)">
                        修改资料
                      </a>
                    </a-menu-item>
                    <a-menu-item key="1">
                      <a href="#" rel="noopener noreferrer">
                        绑定微信
                      </a>
                    </a-menu-item>
                    <a-menu-item key="2">
                      <a href="#" rel="noopener noreferrer">
                        删除
                      </a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </span>
          </template>
        </template>
      </a-table>
      <employee-editor v-model:visible="editorVisible" v-model:employee="employee"
        :position-options="positionOptions"></employee-editor>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref, Ref, h, onMounted
} from 'vue'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons-vue'
import PageTitle from '@/components/PageTitle.vue'
import EmployeeEditor from '@/components/EmployeeEditor.vue'
import { getEmployeeList, getPositionList } from '@/api/employee_api'
import { Employee, Position } from '@/models/Employee'
import { SelectOption } from '@/models/Types'

const columns = ref([
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '性别',
    dataIndex: 'gender',
    key: 'gender',
    filters: [
      {
        text: '男',
        value: 'male'
      },
      {
        text: '女',
        value: 'female'
      }
    ]
  },
  {
    title: '电话',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: '职位',
    key: 'position',
    dataIndex: 'position',
    filters: []
  },
  {
    title: '入职日期',
    key: 'createDate',
    dataIndex: 'createDate'
  },
  {
    title: '微信号',
    key: 'hasBondWeChat',
    dataIndex: 'hasBondWeChat',
    filters: [
      {
        text: '已绑定',
        value: 'bind'
      },
      {
        text: '未绑定',
        value: 'no-bind'
      }
    ]
  },
  {
    title: '操作',
    key: 'action'
  }
])

// 定义响应式表格数据
const data: Ref<Employee[]> = ref([])
const loading: Ref<boolean> = ref(false)

// 根据条件查询员工列表
async function query() {
  loading.value = true
  const response = await getEmployeeList()
  loading.value = false
  if (response.errorCode !== 0 || response.data == null) {
    // TODO: 这里要进行错误提示
    return
  }

  data.value = response.data.rows
}

// 定义员工编辑器参数
const editorVisible = ref(false)
const employee: Ref<Employee | null> = ref(null)
const positionOptions: Ref<SelectOption[]> = ref([])

// 获取职位列表
async function refreshPositionList() {
  const response = await getPositionList()
  if (response.errorCode !== 0 || response.data == null) {
    return
  }

  const positionFilters:any = []
  positionOptions.value = []
  response.data.rows.forEach((item: Position) => {
    positionOptions.value.push({
      value: item.positionCode.toString(),
      label: item.name
    })

    positionFilters.push({
      text: item.name,
      value: item.positionCode.toString()
    })
  })

  // 设置职位筛选列表
  columns.value.forEach((c, index) => {
    if (c.key === 'position') {
      columns.value[index].filters = positionFilters
    }
  })
}

// 新增员工
async function onAddEmployee() {
  employee.value = null
  editorVisible.value = true
}

// 修改员工信息
async function onEditEmployee(e: Employee) {
  employee.value = { ...e }
  editorVisible.value = true
}

onMounted(() => {
  // 页面首次加载时，查询员工列表
  query()

  // 更新职位列表及职位筛选列表
  refreshPositionList()
})

</script>

<style scoped>
.filter_box {
  margin-bottom: 24px;
}

.filter_input150 {
  width: 150px;
}
</style>
