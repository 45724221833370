<template>
  <div>
    <page-title>职位管理</page-title>
    <div class="desktop-block">
      <a-flex justify="space-between" align="center">
        <a-space size="large">
          <a-input-search placeholder="按职位名称搜索" :allowClear="true" enter-button @search="query" />
        </a-space>
        <a-button type="default" danger :icon="h(PlusOutlined)" @click="onAddPosition">新增职位</a-button>
      </a-flex>
      <div class="v-space-24"></div>
      <a-table :columns="columns" :data-source="data" rowKey="positionCode" :loading="loading">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
            <a href="#" @click.prevent="onEditPosition(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除该职位吗？" @confirm="onErasePosition(record.positionCode)">
              <a href="#">删除</a>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
      <position-editor v-model:visible="editorVisible" :position="position" :permission-tree="permissionTree"
        :permission-checked="[]"></position-editor>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Ref, ref, h
} from 'vue'
import PageTitle from '@/components/PageTitle.vue'
import { Position, PermissionGroup } from '@/models/Employee'
import { getPositionList, erasePosition, getPermissionTree } from '@/api/employee_api'
import { PlusOutlined } from '@ant-design/icons-vue'
import PositionEditor from '@/components/PositionEditor.vue'

const columns = [
  {
    title: '职位',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime'
  },
  {
    title: '操作',
    key: 'action'
  }
]

const data: Ref<Position[]> = ref([])
const loading: Ref<boolean> = ref(false)

async function query() {
  loading.value = true
  const response = await getPositionList()
  loading.value = false
  if (response.errorCode !== 0 || response.data == null) {
    return
  }

  data.value = response.data.rows
}

// 定义职位编辑器参数
const editorVisible = ref(false)
const position: Ref<Position | null> = ref(null)
const permissionTree: Ref<PermissionGroup[]> = ref([])

async function updatePermissionTree() {
  const response = await getPermissionTree()
  if (response.errorCode === 0 && response.data != null) {
    permissionTree.value = [...response.data.rows]
  }
}

async function onAddPosition() {
  await updatePermissionTree()
  editorVisible.value = true
  position.value = null
}

async function onEditPosition(item: Position) {
  await updatePermissionTree()
  editorVisible.value = true
  position.value = { ...item }
}

async function onErasePosition(positionCode: number) {
  await erasePosition(positionCode)
  query()
}

query()

</script>
