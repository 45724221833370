<template>
  <div>
    <a-menu v-model:selectedKeys="menu.selectedKeys" @click="onMenuClicked"
            :style="menuStyle" mode="inline" :open-keys="menu.openKeys"
            :items="menuItems" @openChange="onMenuOpenChanged"></a-menu>
  </div>
</template>
<script lang="ts" setup>
import {
  VueElement, h, reactive, defineProps, computed
} from 'vue'
import { AppstoreOutlined } from '@ant-design/icons-vue'
import { ItemType } from 'ant-design-vue'
import { useRouter } from 'vue-router'

const props = defineProps<{
  width: number
}>()

// 定义菜单的宽度样式
const menuStyle = computed(() => `width: ${props.width}px`)

function getItem(
  label: VueElement | string,
  key: string,
  path: string | null,
  icon?: any,
  children?: ItemType[],
  type?: 'group'
): ItemType {
  return {
    key,
    path,
    icon,
    children,
    label,
    type
  } as ItemType
}

// 定义菜单项列表
const menuItems: ItemType[] = reactive([
  getItem('首页', 'home', '/', () => h(AppstoreOutlined)),
  getItem('门店配置', 'm', '/merchant', () => h(AppstoreOutlined)),
  getItem('会员管理', 'h', null, () => h(AppstoreOutlined), [
    getItem('会员等级', 'h-1', '/membershipLevel'),
    getItem('会员列表', 'h-2', '/membershipList')
  ]),
  getItem('员工管理', 'y', null, () => h(AppstoreOutlined), [
    getItem('职位管理', 'y-1', '/positionList'),
    getItem('员工列表', 'y-2', '/employeeList')
  ]),
  getItem('商品管理', 's', null, () => h(AppstoreOutlined), [
    getItem('类目管理', 's-1', '/goodCategories'),
    getItem('商品列表', 's-2', '/goodList')
  ]),
  getItem('营销管理', 'yx', null, () => h(AppstoreOutlined), [
    getItem('优惠券', 'yx-1', '/coupons')
  ]),
  getItem('数据统计', 'st', null, () => h(AppstoreOutlined), [
    getItem('营业统计', 'st-1', null),
    getItem('会员统计', 'st-2', null),
    getItem('商品统计', 'st-3', null)
  ]),
  getItem('系统管理', 'sy', null, () => h(AppstoreOutlined), [
    getItem('个人资料', 'sy-1', '/profile'),
    getItem('修改密码', 'sy-2', '/password')
  ])
])

// 定义菜单的响应式对象，控制只有一个菜单是展开的
const menu = reactive({
  rootSubmenuKeys: ['home', 'm', 'h', 'y', 's', 'yx', 'st', 'sy'],
  openKeys: ['home'],
  selectedKeys: []
})

// 菜单打开项被改变时的回调，控制只有一个菜单是展开的
const onMenuOpenChanged = (openKeys: string[]) => {
  const latestOpenKey = openKeys.find((key) => menu.openKeys.indexOf(key) === -1)
  if (latestOpenKey === undefined) {
    return
  }

  if (menu.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    menu.openKeys = openKeys
  } else {
    menu.openKeys = latestOpenKey ? [latestOpenKey] : []
  }
}

const router = useRouter()

function onMenuClicked(e:any) {
  router.push(e.item.path)
}
</script>

<style scoped>
.ant-menu.ant-menu-root.ant-menu-inline,
.ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}
</style>
