<template>
  <div>
    <a-drawer :width="500" :title="title" placement="right" :keyboard="false" :maskClosable="false" :open="visible"
      @close="onClose">
      <!-- 表单开始 -->
      <a-form :model="data" name="basic" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
        <a-form-item label="职位" name="name">
          <a-input v-model:value="data.name" placeholder="职位名称" />
        </a-form-item>
        <a-form-item label="权限" name="permissions">
          <permission-editor :permission-tree="props.permissionTree" :checked="[]"></permission-editor>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button type="primary" style="margin-right: 8px" @click="onSave">保存</a-button>
        <a-button v-if="!isModeEdit" type="default">保存并继续</a-button>
      </template>
    </a-drawer>
  </div>
</template>

<script setup lang="ts">
import {
  computed, defineProps, defineEmits, Ref, ref, watch
} from 'vue'
import { Permission, PermissionGroup, Position } from '@/models/Employee'
import PermissionEditor from '@/components/PermissionEditor.vue'

// 定义组件的属性
const props = defineProps<{
  visible: boolean
  position: Position | null
  permissionTree: PermissionGroup[]
  permissionChecked: Permission[]
}>()

// 定义默认值
const defaultData = {
  positionCode: 0,
  name: '',
  createTime: ''
}

// 定义编辑器操作的对象
const data: Ref<Position> = ref({ ...defaultData })

watch(() => props.position, (newValue) => {
  if (newValue != null) {
    data.value = newValue
  } else {
    data.value = { ...defaultData }
  }
}, { deep: true })

// 定义组件的事件
const emits = defineEmits(['update:visible', 'update:position'])

// 根据是否指定employeeCode确定是编辑模式还是新增模式
const isModeEdit = computed(() => (props.position != null && props.position.positionCode > 0))
const title = computed(() => (isModeEdit.value ? '修改职位' : '新增职位'))

function onSave() {
  emits('update:visible', false)
  emits('update:position', data.value)
}

function onClose() {
  emits('update:visible', false)
}
</script>

<style></style>
