<template>
  <div>
    <a-drawer :width="500" :title="title" placement="right" :keyboard="false" :maskClosable="false" :open="visible"
      @close="onClose">
      <!-- 表单开始 -->
      <a-form :model="data" name="basic" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
        <a-form-item label="姓名" name="name">
          <a-input v-model:value="data.name" placeholder="员工姓名" />
        </a-form-item>

        <a-form-item label="性别" name="gender">
          <a-select v-model:value="data.gender" placeholder="请选择">
            <a-select-option value="male">男</a-select-option>
            <a-select-option value="female">女</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="电话" name="phone">
          <a-input v-model:value="data.phone" placeholder="手机号码" />
        </a-form-item>

        <a-form-item label="职位" name="positions">
          <a-select :allowClear="true" :showArrow="true" v-model:value="positions" :options="props.positionOptions"
            mode="tags" placeholder="请选择职位"></a-select>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button type="primary" style="margin-right: 8px" @click="onSave">保存</a-button>
        <a-button v-if="!isModeEdit" type="default">保存并继续</a-button>
      </template>
    </a-drawer>
  </div>
</template>

<script setup lang="ts">
import {
  computed, defineProps, defineEmits, Ref, ref, watch
} from 'vue'
import { Employee } from '@/models/Employee'
import { SelectOption } from '@/models/Types'

// 定义组件的属性
const props = defineProps<{
  visible: boolean
  employee: Employee | null
  positionOptions: SelectOption[]
}>()

// 定义默认值
const defaultData = {
  employeeCode: 0,
  name: '',
  gender: null,
  phone: '',
  positions: [],
  positionDescs: [],
  createDate: '',
  hasBondWeChat: false
}

// 定义编辑器操作的对象
const data: Ref<Employee> = ref({ ...defaultData })
const positions: Ref<string[]> = ref([])

watch(() => props.employee, (newValue) => {
  if (newValue != null) {
    data.value = newValue
  } else {
    data.value = { ...defaultData }
  }

  positions.value = []
  data.value.positions.forEach((n: number) => {
    positions.value.push(n.toString())
  })
}, { deep: true })

// 定义组件的事件
const emits = defineEmits(['update:visible', 'update:employee'])

// 根据是否指定employeeCode确定是编辑模式还是新增模式
const isModeEdit = computed(() => (props.employee != null && props.employee.employeeCode > 0))
const title = computed(() => (isModeEdit.value ? '修改员工信息' : '新增员工'))

function onSave() {
  // 保存选择的职位
  data.value.positions = []
  positions.value.forEach((v: string) => {
    data.value.positions.push(parseInt(v, 10))
  })

  emits('update:visible', false)
  emits('update:employee', data.value)
}

function onClose() {
  emits('update:visible', false)
}
</script>

<style></style>
