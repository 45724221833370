import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import HomeView from '@/views/HomeView.vue'
import MerchantView from '@/views/MerchantView.vue'
import EmployeeListView from '@/views/EmployeeListView.vue'
import PositionListView from '@/views/PositionListView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'main',
        component: HomeView
      },
      {
        path: '/merchant',
        name: 'merchant',
        component: MerchantView
      },
      {
        path: '/employeeList',
        name: 'employeeList',
        component: EmployeeListView
      },
      {
        path: '/positionList',
        name: 'positionList',
        component: PositionListView
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
